import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar'; 
import Home from './pages/Home/index';
import Service from './pages/Service/index';
import AboutUs from './pages/About/index';
import Footer from './components/Footer/Footer';
import ContactUs from './pages/Contact/index';
import PrivacyPolicy from './pages/PrivacyPolicy/index';
import Terms from './pages/TermsConditions/TermsConditions'; 

function App() {
  const location = useLocation();

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'G-JWGP9WSR1Y', {
      });
    }
  }, [location]);

  useEffect(() => {
    // Start of Tawk.to Script
    const Tawk_API= (window).Tawk_API || {};
    const Tawk_LoadStart = new Date();

    (function () {
      const s1 = document.createElement("script");
      const s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = 'https://embed.tawk.to/670d04154304e3196ad14f77/1ia5dftoo';
      s1.charset = 'UTF-8';
      s1.setAttribute('crossorigin', '*');
      if (s0.parentNode) {
        s0.parentNode.insertBefore(s1, s0);
      }
    })();
    // End of Tawk.to Script
  }, []);

  return (
    <>
      <Navbar />
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/service" element={<Service />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </main>
      <p>Disclaimer: All product names, logos, and brands are property of their respective owners. All company, product and service names used in this website are for identification purposes only. Use of these names, logos, and brands does not imply any affiliation or endorsement, unless specified otherwise.</p>
      <Footer />
    </>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}
