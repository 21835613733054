import React from 'react';
import styles from './index.module.scss'; // Import the corresponding SCSS file for styles

const ContactUs = () => {
  return (
    <div className={styles.contactContainer}>
      <h1>Contact Us</h1>
      <div className={styles.contactInfo}>
        <div className={styles.contactItem}>
          <h2>Phone Number</h2>
          <p >Need assistance? Call us now at <a href="tel:+18338891827" style={{textDecoration:"none",color:"black"}}>+1 833-889-1827</a> (USA)!</p>
        </div>
        <div className={styles.contactItem}>
          <h2>Email</h2>
          <p>help@emailservicedata.info</p>
        </div>
        <div className={styles.contactItem}>
          <h2>Address</h2>
          <p>H-42,F-block,Sec.63 ,Noida,201305</p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
